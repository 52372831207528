<template>
  <div class="auth-form">
    <div class="form">
      <h1>Smart English</h1>
      <form @submit="login($event)">
        <div class="select-input">
          <vs-icon icon="expand_more" class="select-arrow"></vs-icon>
          <label for="teacher">Teacher</label>
          <select name="login" id="login" v-model="nickname">
            <option
              :value="item.nickname"
              v-for="item in teachers"
              :key="item.id"
            >
              {{ item.nickname }}
            </option>
          </select>
        </div>

        <vs-input
          type="password"
          size="large"
          label-placeholder="Password"
          v-model="password"
        ></vs-input>
        <vs-button @click="login" class="login-button">Login</vs-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      nickname: "",
      password: "",
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
    }),
  },
  methods: {
    login: function (e) {
      e.preventDefault();
      let nickname = this.nickname;
      let password = this.password;
      this.$store
        .dispatch("auth/loginAction", { nickname, password })
        .then(() => {
          this.$router.push({ name: "SmartEnglish" });
          window.location.reload();
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    if (localStorage.getItem("user")) {
      this.$router.push({ name: "SmartEnglish" });
      window.location.reload();
    } else {
      this.$store.dispatch("teachers/getTeachersAction");
    }
    console.log(this.password);
  },
};
</script>

<style lang="scss">
.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: Calc(100vh - 100px);
  .form {
    .vs-input {
      margin-top: 25px;
      width: 100%;
    }
    label {
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.6);
    }
    .select-input {
      position: relative;
      #login {
        display: block;
        background: none;
        border: 1px solid #ccc;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        -webkit-appearance: none;
        appearance: none;
        z-index: 1;
        cursor: pointer;
      }
      .select-arrow {
        position: absolute;
        right: 10px;
        bottom: 7px;
      }
    }

    .con-select {
      width: 100%;
    }
    padding: 60px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    width: 22rem;
    box-shadow: 0 0 4px 3px #eee;
    .login-button {
      margin-top: 15px;
    }
  }
}
</style>